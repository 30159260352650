import React, {useEffect} from 'react';
import './App.css';
import Form from "./Form";

function App() {

    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://form.formcentric.com/form/formcentric.js";

        //For head
        document.head.appendChild(script);
    }, []);
    return (
        <div className="App">
            <header className="App-header">
                <Form/>
            </header>
        </div>
    );
}

export default App;
